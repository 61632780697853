// 根据你的实际请求api.js位置路径修改
import { getByConditionByCompanyLicense, delByIdsLicensePlate, getCompanyGoodNames } from "@/api/index";
// 根据你的实际添加编辑组件位置路径修改
import addEdit from "./addEdit.vue";
import { shortcuts } from "@/libs/shortcuts";
export default {
  name: "PlateInfo",
  props: ['company', 'companyUser'],
  components: {
    addEdit
  },
  data() {
    return {
      itemArray: [],
      height: 600,
      tableSize: "default",
      openTip: true,
      // 显示提示
      showType: "0",
      // 添加或编辑标识
      showDrawer: false,
      // 显示添加编辑抽屉
      loading: true,
      // 表单加载状态
      searchForm: {
        // 搜索框初始化对象
        pageNumber: 1,
        // 当前页数
        pageSize: 10,
        // 页面大小
        sort: "createTime",
        // 默认排序字段
        order: "desc",
        // 默认排序方式
        compId: this.company.id,
        licensePlate: '',
        goodsName: ''
      },
      selectList: [],
      // 多选数据
      form: {},
      data: [],
      // 表单数据
      page: {
        total: 0,
        // 总页数
        currentPage: 1,
        // 当前页数
        pageSize: 20 // 每页显示多少条
      }
    };
  },

  methods: {
    init() {
      this.getDataList();
      getCompanyGoodNames(this.company.id).then(res => {
        let result = res.result;
        this.itemArray = result;
      });
    },
    handlePageChange1({
      currentPage,
      pageSize
    }) {
      this.page.currentPage = currentPage;
      this.page.pageSize = pageSize;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      getByConditionByCompanyLicense(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize
      }, this.searchForm)).then(res => {
        this.loading = false;
        if (res.success) {
          this.data = res.result.records;
          this.page.total = res.result.total;
        }
      });
    },
    add() {
      this.showType = "2";
      this.showDrawer = true;
    },
    edit(v) {
      // 转换null为""
      for (let attr in v) {
        if (v[attr] == null) {
          v[attr] = "";
        }
      }
      let str = JSON.stringify(v);
      let data = JSON.parse(str);
      this.form = data;
      this.showType = "1";
      this.showDrawer = true;
    },
    remove(v) {
      this.$Modal.confirm({
        title: "确认删除",
        // 记得确认修改此处
        content: "您确认要删除该条数据?",
        loading: true,
        onOk: () => {
          // 删除
          delByIdsLicensePlate({
            ids: v.id
          }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    },
    delAll() {
      let selectRecords = this.$refs.table.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$Message.warning("您还未选择要删除的数据");
        return;
      }
      this.$Modal.confirm({
        title: "确认删除",
        content: "您确认要删除所选的 " + selectRecords.length + " 条数据?",
        loading: true,
        onOk: () => {
          let ids = "";
          selectRecords.forEach(function (e) {
            ids += e.id + ",";
          });
          ids = ids.substring(0, ids.length - 1);
          // 批量删除
          delByIdsLicensePlate({
            ids: ids
          }).then(res => {
            this.$Modal.remove();
            if (res.success) {
              this.$Message.success("操作成功");
              this.getDataList();
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();
    this.height = window.innerHeight;
    window.onresize = () => {
      return (() => {
        this.height = window.innerHeight;
      })();
    };
  }
};