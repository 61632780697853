// 根据你的实际请求api.js位置路径修改
import { saveLicensePlate, updateLicensePlate, getCompanyGoodNames } from "@/api/index";
export default {
  name: "addEdit",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    type: {
      type: String,
      default: "0"
    },
    company: {
      type: Object,
      default: {}
    },
    companyUser: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      visible: this.value,
      title: "",
      submitLoading: false,
      maxHeight: 510,
      itemArray: [],
      form: {
        // 添加或编辑表单对象初始化数据
        goodsName: "",
        licensePlate: "",
        compId: this.company.id
      },
      // 表单验证规则
      formValidate: {
        licensePlate: [{
          required: true,
          message: "请输入"
        }],
        goodsName: [{
          required: true,
          message: "请输入"
        }]
      }
    };
  },
  methods: {
    init() {},
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.type == "1") {
            // 编辑
            this.submitLoading = true;
            updateLicensePlate(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          } else {
            // 添加
            this.submitLoading = true;
            saveLicensePlate(this.form).then(res => {
              this.submitLoading = false;
              if (res.success) {
                this.$Message.success("操作成功");
                this.$emit("on-submit", true);
                this.visible = false;
              }
            });
          }
        }
      });
    },
    setCurrentValue(value) {
      if (value === this.visible) {
        return;
      }
      if (this.type == "1") {
        this.title = "编辑";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else if (this.type == "2") {
        this.title = "添加";
        this.maxHeight = Number(document.documentElement.clientHeight - 121) + "px";
      } else {
        this.title = "详细信息";
        this.maxHeight = "100%";
      }
      // 清空数据
      //this.$refs.form.resetFields();
      if (this.type == "0" || this.type == "1") {
        // 回显数据处理
        this.form = this.data;
      } else {
        // 添加
        this.form = {
          licensePlate: "",
          goodsName: "",
          compId: this.company.id
        };
        //delete this.form.id;
      }

      this.visible = value;
    }
  },
  watch: {
    value(val) {
      // alert(val)
      this.setCurrentValue(val);
    },
    visible(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    this.init();
    getCompanyGoodNames(this.company.id).then(res => {
      let result = res.result;
      this.itemArray = result;
    });
  }
};