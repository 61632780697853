var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('el-card', [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "cTitle",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('div', [_c('el-input', {
    staticStyle: {
      "width": "160px",
      "margin-right": "8PX"
    },
    attrs: {
      "placeholder": "车牌号"
    },
    model: {
      value: _vm.searchForm.licensePlate,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "licensePlate", $$v);
      },
      expression: "searchForm.licensePlate"
    }
  }), _c('el-select', {
    staticStyle: {
      "width": "160px"
    },
    attrs: {
      "clearable": "",
      "filterable": "",
      "placeholder": "物料名称"
    },
    model: {
      value: _vm.searchForm.goodsName,
      callback: function ($$v) {
        _vm.$set(_vm.searchForm, "goodsName", $$v);
      },
      expression: "searchForm.goodsName"
    }
  }, _vm._l(_vm.itemArray, function (item) {
    return _c('el-option', {
      attrs: {
        "value": item.goodsName
      }
    }, [_vm._v(_vm._s(item.goodsName))]);
  }), 1), _c('el-button', {
    staticStyle: {
      "margin-left": "8px"
    },
    attrs: {
      "type": "primary",
      "round": "",
      "size": "mini"
    },
    on: {
      "click": _vm.getDataList
    }
  }, [_vm._v("搜索")])], 1)]), _c('div', {
    staticClass: "btnAction"
  }, [_c('el-button', {
    attrs: {
      "type": "primary",
      "round": "",
      "size": "mini"
    },
    on: {
      "click": _vm.add
    }
  }, [_vm._v("添加车牌信息")])], 1)]), _c('div', {
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('vxe-table', {
    ref: "table",
    attrs: {
      "align": "center",
      "export-config": {
        types: ['csv'],
        isHeader: true
      },
      "print-config": {},
      "stripe": "",
      "size": "medium",
      "height": _vm.height - 115 - 50 - 95,
      "loading": _vm.loading,
      "column-config": {
        resizable: true
      },
      "row-config": {
        isHover: true
      },
      "data": _vm.data
    }
  }, [_c('vxe-column', {
    attrs: {
      "fixed": "left",
      "type": "checkbox",
      "width": "40"
    }
  }), _c('vxe-column', {
    attrs: {
      "field": "licensePlate",
      "title": "车牌号"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function ({
        column
      }) {
        return [_c('span', {
          staticStyle: {
            "color": "#909399"
          }
        }, [_vm._v(_vm._s(column.title))])];
      }
    }, {
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('span', [_vm._v(_vm._s(row.licensePlate))])];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "field": "goodsName",
      "title": "物料名称"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function ({
        column
      }) {
        return [_c('span', {
          staticStyle: {
            "color": "#909399"
          }
        }, [_vm._v(_vm._s(column.title))])];
      }
    }, {
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('span', [_vm._v(_vm._s(row.goodsName))])];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "fixed": "right",
      "title": "操作",
      "width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function ({
        row
      }) {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('el-button', {
          attrs: {
            "size": "mini",
            "plain": "",
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row);
            }
          }
        }, [_vm._v("编辑")]), _c('el-button', {
          attrs: {
            "size": "mini",
            "plain": "",
            "type": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c('vxe-column', {
    attrs: {
      "fixed": "right",
      "width": "8"
    }
  })], 1), _c('vxe-pager', {
    attrs: {
      "loading": _vm.loading,
      "current-page": _vm.page.currentPage,
      "page-size": _vm.page.pageSize,
      "total": _vm.page.total,
      "layouts": ['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']
    },
    on: {
      "page-change": _vm.handlePageChange1
    }
  }), _c('addEdit', {
    attrs: {
      "data": _vm.form,
      "company": _vm.company,
      "companyUser": _vm.companyUser,
      "type": _vm.showType
    },
    on: {
      "on-submit": _vm.getDataList
    },
    model: {
      value: _vm.showDrawer,
      callback: function ($$v) {
        _vm.showDrawer = $$v;
      },
      expression: "showDrawer"
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };